var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("header-label", {
            attrs: {
              label: _vm.$t("taxinvoice.header"),
              "prepend-icon": "$taxinvoiceHeader"
            }
          }),
          _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v(" mdi-chevron-left ")
              ])
            ],
            1
          ),
          _c("span", { staticClass: "primary--text mb-3" }, [
            _vm._v(" " + _vm._s(_vm.$t("shopsetting.header")) + " ")
          ])
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _c("select-form", {
                        staticClass: "tax-input-style",
                        attrs: {
                          text: _vm.$t("taxinvoice.vatRunningScope"),
                          state: _vm.state.vatRunningScope,
                          items: _vm.vatScope,
                          "item-text": "description",
                          "item-value": "name"
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("vatRunningScope")
                          }
                        },
                        model: {
                          value: _vm.setting.vatRunningScope,
                          callback: function($$v) {
                            _vm.$set(_vm.setting, "vatRunningScope", $$v)
                          },
                          expression: "setting.vatRunningScope"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-6", attrs: { cols: "7" } },
                    [
                      _c("toggle-form", {
                        staticClass: "mr-12",
                        attrs: {
                          text: _vm.$t("taxinvoice.defaultTaxInvoice"),
                          state: _vm.state.defaultTaxInvoice
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("defaultTaxInvoice")
                          }
                        },
                        model: {
                          value: _vm.setting.defaultTaxInvoice,
                          callback: function($$v) {
                            _vm.$set(_vm.setting, "defaultTaxInvoice", $$v)
                          },
                          expression: "setting.defaultTaxInvoice"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-3", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-6", attrs: { cols: "7" } },
                    [
                      _c("toggle-form", {
                        staticClass: "mr-12",
                        attrs: {
                          text: _vm.$t("taxinvoice.vatExclude"),
                          state: _vm.state.vatExclude
                        },
                        on: {
                          input: function($event) {
                            return _vm.submit("vatExclude")
                          }
                        },
                        model: {
                          value: _vm.setting.vatExclude,
                          callback: function($$v) {
                            _vm.$set(_vm.setting, "vatExclude", $$v)
                          },
                          expression: "setting.vatExclude"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }