<template>
  <div>
    <div class="d-flex align-center">
      <header-label
        :label="$t('taxinvoice.header')"
        prepend-icon="$taxinvoiceHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-card flat>
      <v-card-text>
        <v-row
          class="mt-3"
          no-gutters>
          <v-col cols="9">
            <select-form
              v-model="setting.vatRunningScope"
              :text="$t('taxinvoice.vatRunningScope')"
              :state="state.vatRunningScope"
              :items="vatScope"
              item-text="description"
              item-value="name"
              class="tax-input-style"
              @input="submit('vatRunningScope')">
            </select-form>
          </v-col>
        </v-row>
        <v-row
          class="mt-3"
          no-gutters>
          <v-col
            cols="7"
            class="pr-6">
            <toggle-form
              v-model="setting.defaultTaxInvoice"
              :text="$t('taxinvoice.defaultTaxInvoice')"
              :state="state.defaultTaxInvoice"
              class="mr-12"
              @input="submit('defaultTaxInvoice')" />
          </v-col>
        </v-row>
        <v-row
          class="mt-3"
          no-gutters>
          <v-col
            cols="7"
            class="pr-6">
            <toggle-form
              v-model="setting.vatExclude"
              :text="$t('taxinvoice.vatExclude')"
              :state="state.vatExclude"
              class="mr-12"
              @input="submit('vatExclude')" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { GET_SETTING_TAXINVOICE } from '@/resources/graphql/query/Setting'
import { GET_VATRUNNINGSCOPE_ENUM } from '@/resources/graphql/query/Enum'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'TaxInvoice',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_TAXINVOICE
    }),
    __type: () => ({
      query: GET_VATRUNNINGSCOPE_ENUM,
      result ({ data: { __type } }) {
        this.vatScope = __type.enumValues
      }
    })
  },
  data () {
    return {
      setting: {
        vatRunningScope: '',
        defaultTaxInvoice: false,
        vatExclude: false
      },
      vatScope: [],
      state: {
        vatRunningScope: 'ready',
        defaultTaxInvoice: 'ready',
        vatExclude: 'ready'
      }
    }
  },
  methods: {
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>

<style>
  .tax-input-style .v-select {
    max-width: 300px;
  }
</style>
